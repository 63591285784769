
  import { Account } from "plaid";
  import { Prop, Watch} from "vue-property-decorator";
  import { SortUtils } from "../utils/sort-utils";
  import { NetFlowVue } from "../views/NetFlowBaseVue";

  export default class AccountTable extends NetFlowVue {
    @Prop({type: [Array]}) 
    readonly accounts!: Account[];

    sortUtils = new SortUtils();
    private customSort = false;
    private sortColumn = "none";
    private sortAsc = true;
    $router: any;

    @Watch("accounts", { immediate: true }) onIsInitChanged() {
      if (this.customSort) {
        this.sortUtils.sortAccounts(this.accounts, this.sortColumn, this.sortAsc);
      }
    }

    sortClicked(collection: Account[], columName: string) {
      if (!this.customSort)  {
        this.customSort = true;
        this.sortColumn = "type";
      }
      if (columName === this.sortColumn) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortColumn = columName;
      }
      this.sortUtils.sortAccounts(collection, this.sortColumn, this.sortAsc);
    }

    rowClicked(account_id: string) {
      this.$router.push(`/account/${account_id}/transactions/`)
    }
  }
