
import { InjectReactive, Watch } from "vue-property-decorator";
import { AccountService } from "../services/account";
import { NetFlowPlaidBankLink, NetFlowUser} from "../../../shared/models/account-dto";
import { NetFlowVue } from "./NetFlowBaseVue";
import Swal from 'sweetalert2'
import AccountTable from '../components/AccountTable.vue'
import { Options } from "vue-class-component";

@Options({
  components: {
    AccountTable,
  },
})
export default class AccountView extends NetFlowVue {
  private list: NetFlowPlaidBankLink[] = [];
  private user: NetFlowUser = new NetFlowUser();

  @InjectReactive() isInit!: boolean;
  @Watch("isInit", { immediate: true }) onIsInitChanged() {
    if (this.isInit) {
      this.loadData();
    }
  }

  async loadData() {
   this.user = await new AccountService().getUserAccount(
      this.getAccessToken()
    );
    this.list = this.user.banks;
  }

  fetchTransactions(bankId: string) {
    Swal.fire({
      text: 'Are you sure you want to download transactions for this bank?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        new AccountService().fetchBankTransaction(this.getAccessToken(), bankId).then(result => {
          console.log(result);
            Swal.fire({
            text: result.transactionCount + ' transactions and ' + result.accountCount + ' accounts were updated',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Yes!',
            });
            this.loadData();
        });
      }
    });
  }
}
