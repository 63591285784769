
import { Options, Vue } from 'vue-class-component';
import PlaidLink from '../views/PlaidLink.vue';
import { inject } from 'vue';
import { AccountService } from '../services/account';

@Options({
  components: {
    PlaidLink,
  },
})
export default class Plaid extends Vue {
  private $gAuth: any;
  private Vue3GoogleOauth: any = inject("Vue3GoogleOauth");

  publicToken = '';
  accessToken = '';
  itemId = '';

  async onSuccess(token: string) {
    this.publicToken = token;
    const accessCode = this.$gAuth.instance.currentUser.get().getAuthResponse().access_token;
   
   const tokenResponse= await new AccountService().addBankAccountToUser(accessCode,token);
    this.accessToken = tokenResponse;
    this.itemId = tokenResponse;
  }
}
